<template>
  <div class="dashboard-container">
    <div class="header-container flex flex-between">
      <div class="left-box"></div>
      <div class="right-box flex flex-start">
        <div class="clock">
          <div class="hour-hand"></div>
          <div class="minute-hand"></div>
        </div>
        <div class="date" id="fullScreenBtn1">{{ headDate }}</div>
      </div>
    </div>
    <div class="header-btn-container flex flex-between">
      <div class="left" @click="clickBackBtn">
        <span class="text">返回</span>
      </div>
      <div class="right flex flex-start">
        <img src="@/assets/dashboard/class/header_search_icon.png" alt="" class="search-icon">
        <div class="search-input">
          <input type="text" placeholder="可以输入姓名、学号进行搜索" v-model="searchWord">
        </div>
        <div class="search-btn" @click="clickSearchBtn">
          搜索
        </div>
      </div>
    </div>
    <div class="big-container flex flex-start">
      <div class="left-container">
        <div class="student-list flex flex-dr flex-center">
          <div :class="studentInfo.id===item.id?'li active':'li'" v-for="item in studentList"
               @click="clickStudent(item)">{{ item.name }}
          </div>
        </div>
      </div>
      <div class="right-container flex flex-dr flex-center">
        <div class="overview-box flex flex-start">
          <div class="left flex flex-start">
            <div class="name-box flex flex-dr flex-center">
              <img src="@/assets/dashboard/class/overview_left_sex_female.png" alt="" v-show="studentInfo.sex==='女'">
              <img src="@/assets/dashboard/class/overview_left_sex_male.png" alt="" v-show="studentInfo.sex==='男'">
              <span class="name">{{ studentInfo.name }}</span>
            </div>
            <div class="info-box">
              <div class="li flex flex-start">
                <span class="title">班级：</span>
                <span class="text clazzName">{{ clazzName }}</span>
              </div>
              <div class="li flex flex-start">
                <span class="title">学号：</span>
                <span class="text">{{ studentInfo.account }}</span>
              </div>
              <div class="li flex flex-start">
                <span class="title">最近学习时间：</span>
                <span
                    class="text">{{
                    studentOverview.lastSubmitTime ? date_format(studentOverview.lastSubmitTime, "yyyy-MM-dd HH:mm:ss") : ""
                  }}</span>
              </div>
            </div>
          </div>
          <div class="right flex flex-between">
            <div class="li flex flex-dr flex-center">
              <img src="@/assets/dashboard/v2/class_学习者类型.png" alt="">
              <span class="title">学习者类型</span>
              <span class="number">
                <span v-if="questionnaire">{{ questionnaire.info.studyer }}</span>
                <span v-else>未知</span>
              </span>
            </div>
            <div class="li flex flex-dr flex-center">
              <img src="@/assets/dashboard/v2/class_喜好学习方式.png" alt="">
              <span class="title">喜好的学习方式</span>
              <span class="number">
                 <span v-if="questionnaire">{{ questionnaire.info.studyMethods.join('、') }}</span>
                <span v-else>未知</span>
              </span>
            </div>
          </div>
        </div>
        <div class="bottom-container">
          <div class="bottom-box flex flex-between">
            <div class="left-box flex flex-between">
              <div class="li flex flex-dr flex-center">
                <img src="@/assets/dashboard/class/课程完成率.png" alt="">
                <span class="title">课程完成率</span>
                <span class="number">{{
                    studentOverview.scheduleNum === 0 ? 0 : (studentOverview.completeNum / studentOverview.scheduleNum).toFixed(2) * 100
                  }}%</span>
              </div>
              <div class="li flex flex-dr flex-center">
                <img src="@/assets/dashboard/class/平均得分.png" alt="">
                <span class="title">平均得分</span>
                <span class="number">{{ studentOverview.completeAvgScore.toFixed(0) }}</span>
              </div>
              <div class="li flex flex-dr flex-center">
                <img src="@/assets/dashboard/class/学习最高分.png" alt="">
                <span class="title">学习最高分</span>
                <span class="number">{{ studentOverview.completeMaxScore.toFixed(0) }}</span>
              </div>
            </div>
            <div class="right-box">
              <div class="title-box">课后知识掌握测试</div>
              <div class="chart-box">
                <div class="score-box flex flex-between">
                  <div class="li flex flex-start">
                    <div class="box person"></div>
                    <div class="text">个人成绩：{{ avgScore.student.totalScoreAvg }}</div>
                  </div>
                  <div class="li flex flex-start">
                    <div class="box school"></div>
                    <div class="text">校平均分：{{ avgScore.school.totalScoreAvg }}</div>
                  </div>
                  <div class="li flex flex-start">
                    <div class="box class"></div>
                    <div class="text">班平均分：{{ avgScore.class.totalScoreAvg }}</div>
                  </div>
                </div>
                <div id="avgScore-chart"></div>
              </div>
            </div>
          </div>
          <div class="course-list flex flex-dr flex-center">
            <div class="li flex flex-start" v-for="item in experimentDataList">
              <div class="li-left flex flex-between">
                <div class="avatar-box">
                  <img :src="item.experiment.icon"/>
                </div>
                <div class="numbers">
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">完成人次</span>
                      <span class="number">{{ item.data.completeNum }}/{{ item.data.scheduleNum }}</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show"
                           :style="'width:'+300*(item.data.completeNum/item.data.scheduleNum)+'px'"></div>
                    </div>
                  </div>
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">平均得分</span>
                      <span class="number">{{ item.data.completeAvgScore.toFixed(0) }}分</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show" :style="'width:'+300*(item.data.completeAvgScore/100)+'px'"></div>
                    </div>
                  </div>
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">完成率</span>
                      <span class="number">{{ item.data.completeRate }}%</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show"
                           :style="'width:'+300*(item.data.completeRate/100)+'px'"></div>
                    </div>
                  </div>
                  <div class="number-li">
                    <div class="title flex flex-between">
                      <span class="title-name">平均耗时</span>
                      <span class="number">{{ (item.data.completeAvgUsedTime / 60).toFixed(0) }}分钟</span>
                    </div>
                    <div class="number-bar">
                      <div class="number-bar-bg"></div>
                      <div class="number-bar-show"
                           :style="'width:'+300*(item.data.completeAvgUsedTime /3600 )+'px'"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="li-right flex flex-dr flex-center">
                <div class="line line1 flex flex-start">
                  <div class="line-left">
                    <span class="text">班级综合排名</span>
                  </div>
                  <div class="line-right flex flex-start">
                    <span class="t1">第</span>
                    <div class="box">
                      {{ item.rankObject["classRank"] ? item.rankObject["classRank"] : "大于100" }}
                    </div>
                    <span class="t2">名</span>
                  </div>
                </div>
                <div class="line line2 flex flex-start">
                  <div class="line-left">
                    <span class="text">校内综合排名</span>
                  </div>
                  <div class="line-right flex flex-start">
                    <span class="t1">第</span>
                    <div class="box">
                      {{ item.rankObject["schoolRank"] ? item.rankObject["schoolRank"] : "大于100" }}
                    </div>
                    <span class="t2">名</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="company-box">技术服务：成都智云鸿道信息技术有限公司</div>
    <!--全屏按钮弹出框-->
    <div class="my-dialog-container" v-show="showFullDialog">
      <div class="mask"></div>
      <div class="dialog-box" style="background-color: transparent;width: 100%;top:50%">
        <div class="flex flex-center">
          <el-button id="fullScreenBtn" type="danger" size="">全屏查看</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {StudentModel} from "@/model/exp/StudentModel";
import {StatisticModel} from "@/model/exp/StatisticModel";
import {date_format} from "@/utils/common";
import elDragDialog from "@/directive/el-drag-dialog";
import {ExpQuestionnaireModel} from "@/model/erp/ExpQuestionnaireModel";

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {RadarChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  DataZoomComponent,
  RadarChart
]);

export default {
  name: "v2Class",
  directives: {
    elDragDialog
  },
  data() {
    return {
      date_format: date_format,
      headDate: "",
      headDateTimeId: "",
      schoolId: this.$route.query["schoolId"],
      clazzId: this.$route.query["clazzId"],
      clazzName: this.$route.query["clazzName"],
      studentIndex: this.$route.query["studentIndex"],
      start_time: this.$route.query["start_time"],
      end_time: this.$route.query["end_time"],
      experimentList: [],
      studentList: [],
      studentAccountList: [],
      studentInfo: {},
      experimentDataList: [],
      studentOverview: {
        completeNum: 0,
        scheduleNum: 0,
        completeAvgScore: 0,
        completeMaxScore: 0,
      },
      searchWord: "",
      showFullDialog: false,
      questionnaire: undefined,
      // 课外知识平均分对象
      avgScore: {
        school: {
          got: false,
          totalScoreAvg: 0
        },
        class: {
          got: false,
          totalScoreAvg: 0
        },
        student: {
          got: false,
          totalScoreAvg: 0
        }
      },
    }
  },
  async mounted() {
    // 顶部每秒日期更新
    this.headDateTimeId = setInterval(() => {
      this.formatHeadDate()
    }, 1000)
    await this.getDashboardClazzExperiments()
    await this.getExperimentData()
    this.getStudentList()

    // 如果不是全屏状态就弹出全屏按钮
    if (!document.fullscreenElement) {
      this.showFullDialog = true
    }
    let $this = this;
    document.getElementById("fullScreenBtn").onclick = function () {
      if (document.documentElement.RequestFullScreen) {
        document.documentElement.RequestFullScreen();
      }
      //兼容火狐
      if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      }
      //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
      if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      }
      //兼容IE,只能写msRequestFullscreen
      if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      setTimeout(() => {
        $this.showFullDialog = false
      }, 1000)
    }
    window.onresize = function () {
      if ($this.showFullDialog === false) {
        $this.showFullDialog = true
      }
    }
  },
  beforeDestroy() {
    //  组件销毁时清除定时器
    clearInterval(this.headDateTimeId)
  },
  methods: {
    // 点击搜索按钮
    clickSearchBtn() {
      this.getStudentList()
    },
    // 点击返回按钮
    clickBackBtn() {
      this.$router.push("/dashboard/v2/chart?clazzId=" + this.clazzId + "&schoolId=" +
          this.schoolId + "&clazzName=" + this.clazzName + "&start_time=" + this.start_time + "&end_time=" + this.end_time)
    },
    // 点击某个学生
    async clickStudent(student) {
      this.studentInfo = student
      // 获取这个学生的实验综合统计信息
      this.studentOverview = await StatisticModel.getDashboardClazzOneStudentOverview(student.id)
      // 遍历实验，获取每个实验这个学生的班级和学校排名情况
      for (let i = 0; i < this.experimentDataList.length; i++) {
        let experimentId = this.experimentDataList[i]["experiment"].id;
        let rankObject = await StatisticModel.getOneStudentOneExperimentRankOfClass(student.id, student.administrationClazzId, experimentId, this.schoolId)
        this.$set(this.experimentDataList[i], "rankObject", rankObject)
      }
      // 获取调查表信息
      this.getStudentExpQuestionnaire()
      // 绘制课后知识图
      this.getAvgScore()
    },
    // 获取学生列表
    async getStudentList() {
      let query = {
        clazzid: this.clazzId
      }
      query = Object.assign(query, {
        search: this.searchWord
      });
      [this.studentList,] = await StudentModel.getList(1, 500, query)
      this.studentAccountList = []
      let total = this.studentList.length;
      for (let i = 0; i < total; i++) {
        this.studentAccountList.push(this.studentList[i].account)
      }
      // 默认点击第一个学生
      if (this.studentIndex !== undefined) {
        this.clickStudent(this.studentList[this.studentIndex])
        this.studentIndex = undefined
      } else {
        this.clickStudent(this.studentList[0])
      }

    },
    // 获取本班级安排的所有实验id列表
    async getDashboardClazzExperiments() {
      this.experimentList = await StatisticModel.getDashboardClazzExperiments(this.clazzId)
    },
    // 格式化顶部日期
    formatHeadDate() {
      let date = new Date();
      const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const weekday = weekdays[date.getDay()];
      const hour = date.getHours();
      const minute = date.getMinutes();
      // 使用模板字符串拼接出格式化后的日期时间字符串
      this.headDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${weekday} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
    },
    // 获取某个学生的调查表信息
    async getStudentExpQuestionnaire() {
      let qeList = await ExpQuestionnaireModel.getList({
        schoolId: this.schoolId,
        asOld: false,
        account: this.studentInfo.account
      })
      if (qeList.length > 0) {
        this.questionnaire = qeList[0]
      } else {
        this.questionnaire = undefined
      }
    },
    // 获取课后知识平均分,绘制图表
    async getAvgScore() {
      if (!this.avgScore.school.got) {
        let avgSchool = await ExpQuestionnaireModel.getAvgScoreOfSchool(this.schoolId, null, null, null, null)
        this.avgScore.school = avgSchool
        avgSchool.got = true
        let s = avgSchool["scoreObjectAvg"]
        this.avgScore.school.avgArr = [s["1"], s["2"], s["3"], s["4"], s["5"], s["6"]]
      }
      if (!this.avgScore.class.got) {
        let avgClass = await ExpQuestionnaireModel.getAvgScoreOfClass(this.schoolId, this.studentAccountList, null, null, null)
        this.avgScore.class = avgClass
        avgClass.got = true
        let c = avgClass["scoreObjectAvg"]
        this.avgScore.class.avgArr = [c["1"], c["2"], c["3"], c["4"], c["5"], c["6"]]
      }
      let avgStudent = await ExpQuestionnaireModel.getAvgScoreOfStudent(this.schoolId, this.studentAccountList, this.studentInfo.account, null, null)
      this.avgScore.student = avgStudent
      let p = avgStudent["scoreObjectAvg"]
      let personAvgArr = [p["1"], p["2"], p["3"], p["4"], p["5"], p["6"]]
      let chartDom = document.getElementById("avgScore-chart");
      let myChart = echarts.init(chartDom);
      let option = {
        // legend: {
        //   data: ['校平均分', '班平均分'],
        //   textStyle: {
        //     color: 'rgb(255,255,255,0.9)'
        //   }
        // },
        radar: {
          shape: 'circle',
          indicator: [
            {name: '人物知识'},
            {name: '历史知识'},
            {name: '情感'},
            {name: '价值观'},
            {name: '精神谱系'},
            {name: '思政学科知识'}
          ]
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                areaStyle: {
                  color: "#489996"
                },
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  }
                },
                value: personAvgArr,
                name: '个人成绩'
              },
              {
                areaStyle: {
                  color: "#22527f"
                },
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  }
                },
                value: this.avgScore.school.avgArr,
                name: '校平均分'
              },
              {
                areaStyle: {
                  color: "#54b8db"
                },
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  }
                },
                value: this.avgScore.class.avgArr,
                name: '班平均分'
              }
            ]
          }
        ]
      };
      option && myChart.setOption(option);
    },
    // 获取某个实验的数据
    async getExperimentData() {
      for (let i = 0; i < this.experimentList.length; i++) {
        let experiment = this.experimentList[i]
        let experimentId = experiment.id
        let result = await StatisticModel.dashboard_index_1(this.schoolId, this.clazzId, null, null, experimentId)
        if (result) {
          if (result.scheduleNum === 0) {
            result.completeRate = 0;
          } else {
            result.completeRate = (result.completeNum / result.scheduleNum).toFixed(2) * 100;
          }
          // 获取每个实验的实验用时数据
          let scoreResult = await StatisticModel.dashboard_index_2(this.schoolId, this.clazzId, null, null, experimentId)
          result.completeAvgUsedTime = scoreResult.completeAvgUsedTime // 给单个课程赋值
          // 加入实验列表
          this.experimentDataList.push({
            experiment: experiment,
            data: result,
            rankObject: {}
          })
        }
      }
    },
  }
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

div.dashboard-container {
  background-image: url('../../assets/dashboard/background.png');
  //background-position: center 85%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  height: 100vh;
  width: 100%;
  font-family: MicrosoftYaHei;
  color: #2EB8FF;
  position: relative;
}

// 顶部
div.header-container {
  width: 100%;
  height: 113px;
  background-image: url('../../assets/dashboard/head_title.png');
  background-position: center;

  div.right-box {
    margin-bottom: 40px;
    margin-right: 20px;

    .clock {
      width: 25px;
      height: 25px;
      border: 2px solid #2EB8FF;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }

    .hour-hand {
      width: 2px;
      height: 6px;
      background-color: #2EB8FF;
      position: absolute;
      top: 25%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(0deg);
    }

    .minute-hand {
      width: 2px;
      height: 7px;
      background-color: #2EB8FF;
      position: absolute;
      top: 19%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(140deg);
    }

    .date {
      font-size: 18px;
      color: #2EB8FF;
    }
  }
}

// 顶部按钮框
div.header-btn-container {
  margin-top: -40px;

  div.left {
    margin-left: 20px;
    width: 187px;
    height: 59px;
    position: relative;
    cursor: pointer;
    background-image: url("../../assets/dashboard/class/btn_back.png");
    background-position: center;

    &:hover {
      background-image: url("../../assets/dashboard/class/btn_back_hover.png");
    }


    span.text {
      width: 100px;
      position: absolute;
      left: 100px;
      top: 18px;
      z-index: 1;
      font-size: 25px;
      color: #fff;
    }
  }

  div.right {
    margin-right: 20px;

    img.search-icon {
      width: 39px;
      height: 39px;
    }

    div.search-input {
      width: 542px;
      height: 43px;
      background-image: url('../../assets/dashboard/class/header_search_input_background.png');
      background-position: center;

      input {
        width: 542px;
        height: 43px;
        padding-left: 30px;
        color: #fff;
      }
    }

    div.search-btn {
      cursor: pointer;
      width: 141px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-image: url('../../assets/dashboard/class/header_search_btn_background.png');
      background-position: center;
      letter-spacing: 2px;

      &:hover {
        background-image: url('../../assets/dashboard/class/header_search_btn_active_background.png');
        font-weight: bold;
      }
    }
  }
}

// 大内容框
div.big-container {
  width: 1872px;
  height: 907px;
  margin: 0 auto;
  position: relative;
  background-image: url('../../assets/dashboard/v2/class_big_container_background.png');
  background-position: center;

  div.left-container {
    width: 225px;
    height: 850px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      background-color: #32747c;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #75fbfd;
    }


    div.student-list {
      margin-left: 10px;

      div.li {
        cursor: pointer;
        width: 180px;
        height: 37px;
        line-height: 37px;
        margin-bottom: 15px;
        background-color: #102e40;
        color: #70f1f3;
        text-align: center;

        &:hover, &.active {
          background-color: #235567;
        }
      }
    }
  }

  div.right-container {
    width: 1539px;
    height: 813px;
    margin-left: 35px;
    background-image: url('../../assets/dashboard/v2/class_right_background.png');
    background-position: center;

    div.overview-box {
      padding-bottom: 40px;
      height: 250px;

      div.left {
        width: 612px;
        height: 232px;
        background-image: url('../../assets/dashboard/class/overview_left_background.png');
        background-position: center;

        .name-box {
          height: 150px;

          img {
            width: 158px;
            height: 152px;
          }

          span.name {
            margin-top: -40px;
            color: #fff;
            font-weight: bold;
            letter-spacing: 2px;
            font-size: 25px;
          }
        }

        .info-box {
          width: 435px;
          height: 182px;
          background-image: url('../../assets/dashboard/class/学生信息底框.png');
          background-position: center;
          padding-top: 20px;
          padding-left: 45px;

          .li {
            margin-bottom: 30px;
          }

          .title {
            color: #fff;
            font-size: 20px;
          }

          .text {
            display: inline-block;
            max-width: 300px;
            font-size: 20px;
          }

          .text.clazzName{
            overflow:hidden;
            height: 23px;
          }
        }
      }

      div.right {
        width: 810px;
        margin-left: 50px;

        .li {
          width: 387px;
          height: 228px;
          background-image: url('../../assets/dashboard/v2/class_right_head_li_background.png');
          background-position: center;
        }

        img {
          width: 129px;
          height: 89px;
        }

        .title {
          margin-top: 25px;
          font-size: 24px;
          font-family: SimHei;
          color: #10E4E5;
        }

        .number {
          margin-top: 15px;
          font-size: 17px;
          color: #ebeef2;
        }
      }
    }

    div.bottom-container {
      height: 500px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        background-color: #32747c;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #75fbfd;
      }
    }

    div.bottom-box {
      div.left-box {
        width: 880px;

        .li {
          width: 269px;
          height: 346px;
          background-image: url('../../assets/dashboard/v2/class_bottom_li_background.png');
          background-position: center;
        }

        img {
          width: 129px;
          height: 89px;
        }

        .title {
          margin-top: 25px;
          font-size: 24px;
          font-family: SimHei;
          color: #10E4E5;
        }

        .number {
          margin-top: 15px;
          font-size: 20px;
          color: #ebeef2;
        }
      }

      div.right-box {
        margin-left: 30px;
        width: 572px;
        height: 399px;
        background-image: url('../../assets/dashboard/v2/class_bottom_right_background.png');
        background-position: center;
        position: relative;

        div.title-box {
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          color: #63d8e2;
          font-size: 18px;
        }

        div.chart-box {
          height: 370px;
          padding-top: 30px;
          position: relative;

          #avgScore-chart {
            height: 370px;
            padding-top: 30px;
          }

          .score-box {
            position: absolute;
            transform: translateX(-50%);
            width: 380px;
            left: 55%;
            top: 50px;

            .li {
              margin-right: 20px;

              .box {
                width: 15px;
                height: 15px;
                margin-right: 5px;

                &.person {
                  background-color: #54858a;
                }

                &.school {
                  background-color: #22527f;
                }

                &.class {
                  background-color: #54b8db;
                }
              }

              .text {
                font-size: 13px;
                color: #fff;
              }
            }
          }
        }
      }
    }

    div.course-list {
      margin-top: 10px;
      width: 1400px;
      padding-left: 57px;
      padding-top: 30px;


      .li {
        width: 1330px;
        border: 2px solid #3f8ed6;
        margin-bottom: 15px;
        padding-right: 30px;
      }

      .li-left {
        width: 490px;
        padding: 10px 15px;
        margin-right: 200px;
        margin-left: 50px;

        .avatar-box {
          width: 153px;
          height: 146px;
          padding: 15px 14px 15px 14px;
          background-image: url('../../assets/dashboard/pic_kuang.png');
          background-position: center;
          margin-right: 30px;

          img {
            width: 125px;
            height: 120px;
          }
        }

        .numbers {
          .number-li {
            width: 300px;
            height: 40px;
            color: #bacfdd;
            font-size: 16px;

            .title {
              margin-bottom: 2px;
            }

            .number-bar {
              position: relative;

              .number-bar-bg {
                background-color: #1f222f;
                width: 300px;
                height: 15px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 0;
              }

              .number-bar-show {
                background: linear-gradient(to right, #87dbd1 0%, #4b97f6 100%);
                width: 100px;
                height: 15px;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
              }
            }
          }
        }
      }

      .li-right {
        .line {
          margin-bottom: 15px;

          .line-left {
            margin-right: 40px;

            span.text {
              color: #5bc8e1;
              font-size: 25px;
            }
          }

          .line-right {
            font-size: 23px;
            color: #65daef;

            .t1 {
              margin-right: 12px;
            }

            .t2 {
              margin-left: 12px;
            }

            .box {
              width: 165px;
              height: 57px;
              line-height: 57px;
              text-align: center;

              color: #6be7fc;
              font-size: 30px;
              background-image: url('../../assets/dashboard/class/排名边框.png');
              background-position: center;
            }
          }
        }

        .line1 {
          .line-left {
            width: 257px;
            height: 49px;
            text-align: center;
            padding-left: 40px;
            line-height: 49px;
            background-image: url('../../assets/dashboard/class/班级综合排名.png');
            background-position: center;
          }
        }

        .line2 {
          .line-left {
            width: 257px;
            height: 49px;
            text-align: center;
            line-height: 49px;
            padding-left: 40px;
            background-image: url('../../assets/dashboard/class/校内综合排名.png');
            background-position: center;
          }
        }
      }
    }
  }
}

div.company-box {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  width: 350px;
  height: 17px;
  font-size: 18px;
  font-family: SimHei;
  color: #50A2B0;
  line-height: 28px;
  background: radial-gradient(NaNdeg, #209CFF 0%, #68E0CF 0%);
  -webkit-background-clip: text;
}
</style>
